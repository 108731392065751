<template>
    <div class="cashout">
        <el-card>
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>我的认证资料</span>
                </div>
            </template>
            <el-table :data="tableData" table-layout="fixed">
                <el-table-column prop="onlinestore" label="商铺名称" />
                <el-table-column prop="runname" label="运营联系人" />
                <el-table-column prop="submittime" label="更新时间" />
                <el-table-column prop="id" label="操作">
                    <template #default="scope">
                        <el-button @click="search(scope.row.id)" size="small" type="primary">查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>


</template>
<script>
import constant from '@/constant'
export default {
    data() {
        return {
            tableData: []
        }
    },
    methods: {
        search(id) {
            console.log(id)
            this.$router.push({
                path: '/personinfo',
                query: {
                    id: id,
                }
            })
        }
    },
    created: function () {


        this.axios.get(constant.merchantinfo).then((response) => {
            console.log(response.data)
            this.tableData = response.data
        })
    }
}
</script>
<style>
.el-card {
    padding: 0 !important;
}

.el-card__body {
    padding: 10px;
}

.el-card__header {
    padding: 10px !important;
}

.el-form-item__content {
    margin-left: 0;
}

.el-form-item .is-validating {
    margin-bottom: 0;
}
</style>